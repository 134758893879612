.body {
  font-family: "VAG-Rounded-Std";
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f1f1fb;
}

@font-face {
  font-family: "VAG-Rounded-Std";
  src: url("./VAGRoundedStd-Bold.woff2") format("woff2"),
    url("./VAGRoundedStd-Bold.woff") format("woff");
}

.left-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px 80px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 20px;
  height: auto;
}

.logo {
  margin-top: 20px;
  height: 100px;
}

.instructions {
  font-size: 17px;
  color: black;
  padding: 5px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  height: 50px;
  min-width: 275px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  box-shadow: 1px 1px 1px 1px lightgray;
}

.input:required:invalid {
  border: 1px, red, solid;
}

.forgot-pass {
  color: black;
  text-decoration: underline;
  white-space: nowrap;
}

.error {
  color: red;
  font-size: 15px;
  margin: 2px;
  display: inherit;
}

.no-error {
  display: none;
}

.no-account {
  display: flex;
  margin-top: 75px;
  margin-bottom: 75px;
  white-space: nowrap;
}

.no-account > div {
  font-size: 15px;
  margin-right: 5px;
}

.no-account > a {
  font-size: 15px;
  color: #3268fa;
}

.footer-img {
  height: 20px;
  bottom: 10px;
  position: absolute;
}

.right-div {
  width: 50%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("./map.png");
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
}

.announcement {
  background-color: white;
  border-radius: 10px;
  width: 660px;
  height: 420px;
  opacity: 1;
}

.iframe {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .body {
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .right-div {
    display: none;
  }

  .left-div {
    width: 100%;
  }
}
