#question-options {
  position: relative;
  min-height: 150px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #b7a9a9;
  border-radius: 4px;
}

#question-options-button-container {
  width: 100%;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
}
#question-options-button {
  text-transform: none;
}

.question-options-inner {
  min-height: 150px;
  max-height: 350px;
  overflow: auto;
}

.question-option-row:nth-child(even) {
  background: #ccc;
}
.question-option-row:nth-child(odd) {
  background: #fff;
}

#question-option {
  /* border: 1px solid black; */
  width: 90%;
  border-radius: 4px;
  margin: 10px auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

#question-preview {
  margin-top: 30px;
}

#option-previews {
  margin-top: 10px;
}

.option-preview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.option-preview {
  margin-top: 10px !important;
}

.question-option-row input {
  border: 1px solid black;
  padding: 10px;
  color: #3b1919;
}

.image-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-field-container .image-option-button {
  margin-top: 10px;
}

.image-field-container input {
  margin-left: 20px;
}

.image-option-holder {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-option-holder .fa-camera {
  font-size: 30px;
}

.image-option-holder .fa-camera {
  font-size: 30px;
}

.image-field-container i {
  font-size: 30px;
  margin-top: 5px;
}

.image-field-container .MuiInput-root {
  margin-right: 20px;
}

.option {
  min-width: 120px;
  min-height: 80px;
  background-color: #117efb;
  border-radius: 20px;
  padding: 5px;
}

.icon-option,
.text-option {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  color: white;
}

.icon-option i {
  color: yellow;
  margin-bottom: 10px;
  font-size: 30px;
}

.option-text-holder {
  color: white;
  font-weight: 600;
  width: 100%;
}

.preview-free-form {
  padding: 10px;
  width: 260px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #ccc;
  margin-bottom: 5px;
}

.free-form-instructions {
  font-size: 12px;
  margin-left: 8px;
  color: rgb(134, 128, 128);
}

.bubble {
  box-shadow: rgb(90 60 122 / 22%) 0px 1px 28px 0px;
  min-width: 42px;
  border-radius: 30px;
  background-color: rgb(228, 228, 235);
  box-sizing: content-box;
  min-height: 1.35em;
  display: inline-block;
  max-width: 82%;
  overflow: hidden;
  padding: 16px 24px;
  transition: opacity 0.5s ease 0s, background-color 0.75s ease 0s,
    color 0.75s ease 0s;
  color: black;
  margin-bottom: 35px;
  margin-right: 10%;
  font-family: "Open Sans", sans-serif;
  max-width: 275px;
}

.question-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 600px;
  margin: auto;
}

.avatar-holder {
  order: 0;
  padding: 6px;
  position: absolute;
  top: 0;
  left: 0;
  float: left;
}

.avatar-holder img {
  border-radius: 50% 50% 0px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  transform-origin: right bottom;
}

.text-field {
  margin-top: 20px;
}
.text-field > div {
  border: 1px solid black;
}
