.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.program-error {
  position: absolute;
  left: -30px;
  color: red;
  margin-top: 18px;
}

.dev-container {
  background: white;
  min-width: 300px;
  max-width: 300px;
  border: 1px solid;
  position: fixed;
  height: 400px;
  padding: 5px;
  margin-left: 25px;
  z-index: 1000;
  border-radius: 0 !important;
}

.dev-container-expanded {
  transition: left 1s !important;
  left: -25px;
}

.dev-container-collapsed {
  left: -367px;
  transition: left 1s !important;
}

.expand-button {
  right: -20px;
  position: absolute !important;
  height: 100px !important;
  width: 20px !important;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  text-align: center !important;
  padding: 0 !important;
  box-shadow: none !important;
  min-width: 0 !important;
}
