.buttonLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 275px;
  border: none;
  border-radius: 10px;
  padding-left: 20px;
  color: white;
  background-color: #3268fa;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
}

.spinner {
  height: 25px;
  width: 25px;
  margin-left: 10px;
}
.buttonLoader .spinning.spinner {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
  color: black;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
