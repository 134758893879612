#step {
  display: flex;
}

#step-guide {
  background-color: #4d7cff;
  overflow-y: scroll;
}

#step-form {
  overflow-y: scroll;

  height: calc(100vh - 101px);
  padding: 20px;
  padding-left: 24px;
  background-color: #d1e8ef;
  margin-bottom: 40px;
}

#step-form-button-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  padding-bottom: 10px;
  border-top: 1px solid black;
}

#step-guide-inner {
  color: white;
  margin-top: 25px;
}

.step-container {
  height: 100vh;
  position: relative;
  width: 50% !important;
}

#left-step-container {
  padding-right: 0;
  padding-left: 0;
}

.program-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.rjsf {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}

#step-form .MuiDivider-root {
  height: 2px !important;
  margin-top: 10px !important;
}

.MuiButton-label {
  font-weight: bolder;
}

.step-title {
  font-size: 40px;
  color: white;
  font-family: "VAG-Rounded-Std" !important;
  line-height: 1.1em;
  margin: 20px;
  margin-top: 30px;
  line-height: 1.1em;
}

.sidebar-icon {
  font-size: 160px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.remove-button {
  color: red;
}

.csv-label {
  font-weight: bold;
  color: blue;
}

.step-description-ul {
  max-width: 80%;
  text-align: left;
}

.step-description-ul li {
  margin-bottom: 40px;
  line-height: 24px;
}

#step-guide-inner,
.step-description {
  line-height: 26px !important;
  font-size: 20px !important;
}
