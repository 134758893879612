body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.program-error {
  position: absolute;
  left: -30px;
  color: red;
  margin-top: 18px;
}

.dev-container {
  background: white;
  min-width: 300px;
  max-width: 300px;
  border: 1px solid;
  position: fixed;
  height: 400px;
  padding: 5px;
  margin-left: 25px;
  z-index: 1000;
  border-radius: 0 !important;
}

.dev-container-expanded {
  transition: left 1s !important;
  left: -25px;
}

.dev-container-collapsed {
  left: -367px;
  transition: left 1s !important;
}

.expand-button {
  right: -20px;
  position: absolute !important;
  height: 100px !important;
  width: 20px !important;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  text-align: center !important;
  padding: 0 !important;
  box-shadow: none !important;
  min-width: 0 !important;
}

.body {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f1f1fb;
}

.left-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px 80px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 20px;
  height: auto;
}

.logo {
  margin-top: 20px;
  height: 100px;
}

.instructions {
  font-size: 17px;
  color: black;
  padding: 5px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.instructions1 {
  font-size: 17px;
  color: black;
  padding: 2px;
  margin-top: 15px;
  white-space: nowrap;
}

.instructions2 {
  font-size: 17px;
  color: black;
  padding: 2px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  height: 50px;
  min-width: 275px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  box-shadow: 1px 1px 1px 1px lightgray;
}

.forgot-pass {
  color: black;
  text-decoration: underline;
  white-space: nowrap;
}

.error {
  color: red;
  font-size: 15px;
  margin: 2px;
  display: inherit;
}

.no-error {
  display: none;
}

.signIn-button {
  height: 50px;
  min-width: 275px;
  border: none;
  border-radius: 10px;
  padding-left: 20px;
  color: white;
  background-color: #3268fa;
  font-size: 20px;
  margin: 10px;
}

.link-success {
  font-size: 18px;
}

.instructions-success {
  font-size: 20px;
  color: green;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.resend-code {
  display: flex;
  margin-top: 75px;
  margin-bottom: 75px;
  white-space: nowrap;
}

.resend-code > div {
  font-size: 15px;
  margin-right: 5px;
}

.resend-code > a {
  font-size: 15px;
  color: #3268fa;
}

.footer-img {
  height: 20px;
  bottom: 10px;
  position: absolute;
}

.right-div {
  width: 50%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url(/static/media/map.db7ea24c.png);
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
}

.announcement {
  background-color: white;
  border-radius: 10px;
  width: 70%;
  height: 50%;
  opacity: 1;
}

.iframe {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .body {
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .right-div {
    display: none;
  }

  .left-div {
    width: 100%;
  }
}

.buttonLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 275px;
  border: none;
  border-radius: 10px;
  padding-left: 20px;
  color: white;
  background-color: #3268fa;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
}

.spinner {
  height: 25px;
  width: 25px;
  margin-left: 10px;
}
.buttonLoader .spinning.spinner {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  color: black;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

.body {
  font-family: "VAG-Rounded-Std";
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f1f1fb;
}

@font-face {
  font-family: "VAG-Rounded-Std";
  src: url(/static/media/VAGRoundedStd-Bold.c1ed3741.woff2) format("woff2"),
    url(/static/media/VAGRoundedStd-Bold.162cd9d0.woff) format("woff");
}

.left-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px 80px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 20px;
  height: auto;
}

.logo {
  margin-top: 20px;
  height: 100px;
}

.instructions {
  font-size: 17px;
  color: black;
  padding: 5px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  height: 50px;
  min-width: 275px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  box-shadow: 1px 1px 1px 1px lightgray;
}

.input:required:invalid {
  border: 1px, red, solid;
}

.forgot-pass {
  color: black;
  text-decoration: underline;
  white-space: nowrap;
}

.error {
  color: red;
  font-size: 15px;
  margin: 2px;
  display: inherit;
}

.no-error {
  display: none;
}

.no-account {
  display: flex;
  margin-top: 75px;
  margin-bottom: 75px;
  white-space: nowrap;
}

.no-account > div {
  font-size: 15px;
  margin-right: 5px;
}

.no-account > a {
  font-size: 15px;
  color: #3268fa;
}

.footer-img {
  height: 20px;
  bottom: 10px;
  position: absolute;
}

.right-div {
  width: 50%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url(/static/media/map.db7ea24c.png);
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
}

.announcement {
  background-color: white;
  border-radius: 10px;
  width: 660px;
  height: 420px;
  opacity: 1;
}

.iframe {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .body {
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .right-div {
    display: none;
  }

  .left-div {
    width: 100%;
  }
}

#step {
  display: flex;
}

#step-guide {
  background-color: #4d7cff;
  overflow-y: scroll;
}

#step-form {
  overflow-y: scroll;

  height: calc(100vh - 101px);
  padding: 20px;
  padding-left: 24px;
  background-color: #d1e8ef;
  margin-bottom: 40px;
}

#step-form-button-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  padding-bottom: 10px;
  border-top: 1px solid black;
}

#step-guide-inner {
  color: white;
  margin-top: 25px;
}

.step-container {
  height: 100vh;
  position: relative;
  width: 50% !important;
}

#left-step-container {
  padding-right: 0;
  padding-left: 0;
}

.program-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.rjsf {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}

#step-form .MuiDivider-root {
  height: 2px !important;
  margin-top: 10px !important;
}

.MuiButton-label {
  font-weight: bolder;
}

.step-title {
  font-size: 40px;
  color: white;
  font-family: "VAG-Rounded-Std" !important;
  line-height: 1.1em;
  margin: 20px;
  margin-top: 30px;
  line-height: 1.1em;
}

.sidebar-icon {
  font-size: 160px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.remove-button {
  color: red;
}

.csv-label {
  font-weight: bold;
  color: blue;
}

.step-description-ul {
  max-width: 80%;
  text-align: left;
}

.step-description-ul li {
  margin-bottom: 40px;
  line-height: 24px;
}

#step-guide-inner,
.step-description {
  line-height: 26px !important;
  font-size: 20px !important;
}

.container {
  padding-top: 32px;
  padding-bottom: 8px;
}

.context {
  position: relative;
}

h2 {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  -webkit-margin-before: 0.83em;
          margin-block-start: 0.83em;
  -webkit-margin-after: 0.83em;
          margin-block-end: 0.83em;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  margin-inline-end: 0px;
}

.form_group {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

.branding-info-button {
  color: #fff;
  background-color: #3f51b5;
  margin-left: 25;
  border-radius: 4;
}

.branding-image-button,
.image-option-button {
  border: 1px solid black;
  position: relative;
  overflow: hidden;
  padding: 1px;
}

.branding-image-button {
  margin: 10px 10px 10px 0;
}

.image-option-button {
  margin: 0px;
  margin-left: 10px;
}

.image-option-button > div {
  display: none;
}

.branding-image-button:hover,
.image-option-button:hover {
  color: white;
  background-color: rgba(32, 33, 36, 0.6);
  cursor: pointer;
}

.branding-image-button:focus,
.image-option-button:focus {
  outline: none;
  box-shadow: none;
}

.branding-image-button-avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: none;
}

.branding-image {
  max-width: 300px;
}

#valueadddescription {
  overflow: hidden;
  min-height: 38px;
  width: 200px;
  padding: 0px;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid;
  resize: none;
}

#tagline {
  width: 200px;
  padding: 0px;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid;
}

.avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.image-input-label {
  border: 1px dotted black;
  height: 24px;
  width: 24px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  margin-left: 10px;
}

.image-input-label:hover {
  background-color: rgba(32, 33, 36, 0.6);
  cursor: pointer;
}

#edit-offer-main {
  margin-top: 20px;
}

#create-offer-dates {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

#create-offer-dates > div {
  width: 45%;
  margin-right: 20px;
}

.offer-checkboxes {
  text-align: left;
  margin-top: 20px;
}

#question-options {
  position: relative;
  min-height: 150px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #b7a9a9;
  border-radius: 4px;
}

#question-options-button-container {
  width: 100%;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
}
#question-options-button {
  text-transform: none;
}

.question-options-inner {
  min-height: 150px;
  max-height: 350px;
  overflow: auto;
}

.question-option-row:nth-child(even) {
  background: #ccc;
}
.question-option-row:nth-child(odd) {
  background: #fff;
}

#question-option {
  /* border: 1px solid black; */
  width: 90%;
  border-radius: 4px;
  margin: 10px auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

#question-preview {
  margin-top: 30px;
}

#option-previews {
  margin-top: 10px;
}

.option-preview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.option-preview {
  margin-top: 10px !important;
}

.question-option-row input {
  border: 1px solid black;
  padding: 10px;
  color: #3b1919;
}

.image-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-field-container .image-option-button {
  margin-top: 10px;
}

.image-field-container input {
  margin-left: 20px;
}

.image-option-holder {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-option-holder .fa-camera {
  font-size: 30px;
}

.image-option-holder .fa-camera {
  font-size: 30px;
}

.image-field-container i {
  font-size: 30px;
  margin-top: 5px;
}

.image-field-container .MuiInput-root {
  margin-right: 20px;
}

.option {
  min-width: 120px;
  min-height: 80px;
  background-color: #117efb;
  border-radius: 20px;
  padding: 5px;
}

.icon-option,
.text-option {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  color: white;
}

.icon-option i {
  color: yellow;
  margin-bottom: 10px;
  font-size: 30px;
}

.option-text-holder {
  color: white;
  font-weight: 600;
  width: 100%;
}

.preview-free-form {
  padding: 10px;
  width: 260px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #ccc;
  margin-bottom: 5px;
}

.free-form-instructions {
  font-size: 12px;
  margin-left: 8px;
  color: rgb(134, 128, 128);
}

.bubble {
  box-shadow: rgb(90 60 122 / 22%) 0px 1px 28px 0px;
  min-width: 42px;
  border-radius: 30px;
  background-color: rgb(228, 228, 235);
  box-sizing: content-box;
  min-height: 1.35em;
  display: inline-block;
  max-width: 82%;
  overflow: hidden;
  padding: 16px 24px;
  transition: opacity 0.5s ease 0s, background-color 0.75s ease 0s,
    color 0.75s ease 0s;
  color: black;
  margin-bottom: 35px;
  margin-right: 10%;
  font-family: "Open Sans", sans-serif;
  max-width: 275px;
}

.question-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 600px;
  margin: auto;
}

.avatar-holder {
  order: 0;
  padding: 6px;
  position: absolute;
  top: 0;
  left: 0;
  float: left;
}

.avatar-holder img {
  border-radius: 50% 50% 0px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.text-field {
  margin-top: 20px;
}
.text-field > div {
  border: 1px solid black;
}

.text-field {
  margin-top: 20px !important;
}
.text-field > div {
  border: 1px solid black;
  padding: 5px !important;
}

.company-info-row {
  display: flex !important;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
}

.company-info-row > div {
  margin-right: 20px;
}

#edit-company-main {
  text-align: left;
}
#edit-company-main > div {
  margin-top: 20px;
  display: block;
}

#terms-of-service {
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 5px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #4d7cff;
}

.rg-settings-row {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-field {
  margin-top: 20px;
}
.text-field > div {
  border: 1px solid black;
}
#edit-bot-main {
  text-align: left;
  margin-top: 20px;
}
#salutation > div {
  width: 100%;
}
#zip-codes-container {
  display: flex;
  justify-content: space-evenly;
}

#iframe-container {
  text-align: left !important;
  max-height: 500px;
  overflow: auto;
}

