.company-info-row {
  display: flex !important;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
}

.company-info-row > div {
  margin-right: 20px;
}

#edit-company-main {
  text-align: left;
}
#edit-company-main > div {
  margin-top: 20px;
  display: block;
}

#terms-of-service {
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 5px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #4d7cff;
}

.rg-settings-row {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 30px;
}
