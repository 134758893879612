#edit-offer-main {
  margin-top: 20px;
}

#create-offer-dates {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

#create-offer-dates > div {
  width: 45%;
  margin-right: 20px;
}

.offer-checkboxes {
  text-align: left;
  margin-top: 20px;
}
