.container {
  padding-top: 32px;
  padding-bottom: 8px;
}

.context {
  position: relative;
}

h2 {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-end: 0px;
  margin-inline-end: 0px;
}

.form_group {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

.branding-info-button {
  color: #fff;
  background-color: #3f51b5;
  margin-left: 25;
  border-radius: 4;
}

.branding-image-button,
.image-option-button {
  border: 1px solid black;
  position: relative;
  overflow: hidden;
  padding: 1px;
}

.branding-image-button {
  margin: 10px 10px 10px 0;
}

.image-option-button {
  margin: 0px;
  margin-left: 10px;
}

.image-option-button > div {
  display: none;
}

.branding-image-button:hover,
.image-option-button:hover {
  color: white;
  background-color: rgba(32, 33, 36, 0.6);
  cursor: pointer;
}

.branding-image-button:focus,
.image-option-button:focus {
  outline: none;
  box-shadow: none;
}

.branding-image-button-avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: none;
}

.branding-image {
  max-width: 300px;
}

#valueadddescription {
  overflow: hidden;
  min-height: 38px;
  width: 200px;
  padding: 0px;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid;
  resize: none;
}

#tagline {
  width: 200px;
  padding: 0px;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid;
}

.avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.image-input-label {
  border: 1px dotted black;
  height: 24px;
  width: 24px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  margin-left: 10px;
}

.image-input-label:hover {
  background-color: rgba(32, 33, 36, 0.6);
  cursor: pointer;
}
